<template>
  <div style="height:100%;width:100%">
    <GmapMap
      ref="gmap"
      :center="center"
      :zoom="10"
      :options="options"
      style="width: 100%; height: 100%"
    >
      <GmapMarker
        v-if="isPoint"
        @dragend="onPositionChange"
        :position="position"
        :clickable="true"
        draggable
      />
      <gmap-polygon
        v-if="isPolygon"
        @paths_changed="onPathChange"
        :paths="paths"
        editable
        :options="options"
      ></gmap-polygon>
    </GmapMap>
  </div>
</template>
<script>
import { to } from '@/helpers'
import { gmapApi } from 'vue2-google-maps'
import { mapGetters } from 'vuex'
import { PLACES_UPDATE_PLACE } from '@/store/actions.type'
import { PLACES_SET_LOCATION } from '@/store/mutations.type'
export default {
  name: 'PlacesMap',
  props: {},
  data() {
    return {
      placeId: null,
      place: {},
      paths: [],
      isPoint: false,
      isPolygon: false,
      position: null,
      drawingManager: null,
      options: {
        clickableIcons: false,
        strokeColor: '#460A23',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#460A23',
        fillOpacity: 0.35
      },
      center: {
        lat: 19.4101,
        lng: -99.1699
      }
    }
  },
  computed: {
    google: gmapApi,
    ...mapGetters(['getPlacesSelectedPlaceType'])
  },
  mounted() {},
  watch: {
    getPlacesSelectedPlaceType: function() {
      this.checkPlaceType()
    }
  },
  methods: {
    checkPlaceType() {
      if (this.getPlacesSelectedPlaceType.slug === 'point') {
        this.isPoint = true
        this.isPolygon = false
        this.position = this.center
        this.removeMapDrawingManager()
      }
      if (this.getPlacesSelectedPlaceType.slug === 'polygon') {
        this.isPoint = false
        this.isPolygon = true
        this.loadMapDrawingManager()
      }
    },
    async onPositionChange(location) {
      const newCoordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      }
      const pointGeo = this.pointGeo(newCoordinates)
      this.$store.commit(PLACES_SET_LOCATION, pointGeo)
    },
    onPathChange(mvcArray) {
      const coordinates = []
      for (let i = 0; i < mvcArray.getLength(); i++) {
        const coords = []
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          const coord = mvcArray.getAt(i).getAt(j)
          coords.push([coord.lng(), coord.lat()])
        }
        coords.push(coords[0])
        coordinates.push(coords)
      }
      const polyGeo = this.polyGeo(coordinates)
      this.$store.commit(PLACES_SET_LOCATION, polyGeo)
    },
    pointGeo(coords) {
      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [coords.lng, coords.lat]
        }
      }
    },
    polyGeo(coords) {
      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: coords
        }
      }
    },
    async update(place) {
      this.error = null
      const [err] = await to(this.$store.dispatch(PLACES_UPDATE_PLACE, place))
      if (err) {
        this.error = err
      } else {
        this.snackbar = true
      }
    },
    loadMapDrawingManager() {
      const self = this
      this.$refs.gmap.$mapPromise.then(mapObject => {
        this.drawingManager = new this.google.maps.drawing.DrawingManager({
          drawingControl: true,
          drawingControlOptions: {
            position: this.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [this.google.maps.drawing.OverlayType.POLYGON]
          },
          polygonOptions: {
            fillColor: '#0099FF',
            fillOpacity: 0.7,
            strokeColor: '#AA2143',
            strokeWeight: 4,
            editable: true
          }
        })

        const drawingManager = this.drawingManager

        drawingManager.setMap(this.$refs.gmap.$mapObject)

        this.google.maps.event.addListener(
          drawingManager,
          'overlaycomplete',
          function(event) {
            // Get overlay paths
            const paths = event.overlay.getPaths()
            // Remove overlay from map
            event.overlay.setMap(null)

            // Disable drawingManager
            drawingManager.setDrawingMode(null)

            // Create Polygon
            self.savePolygon(paths)
          }
        )
      })
    },
    removeMapDrawingManager() {
      if (this.drawingManager) {
        this.drawingManager.setMap(null)
      }
    },
    onPathsCreate(array) {
      const coordinates = []
      for (let i = 0; i < array.length; i++) {
        const coords = []
        for (let j = 0; j < array[i].length; j++) {
          const coord = array[i][j]
          coords.push([coord[0], coord[1]])
        }
        coordinates.push(coords)
      }
      const polyGeo = this.polyGeo(coordinates)
      this.$store.commit(PLACES_SET_LOCATION, polyGeo)
    },
    savePolygon(paths) {
      this.paths = paths.getArray()
      this.onPathChange(paths)
    }
  }
}
</script>
