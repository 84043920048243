<template>
  <div style="width: 100%">
    <v-navigation-drawer absolute width="360">
      <v-card dense flat class="mt-2 mb-0">
        <v-toolbar flat dense>
          <v-btn icon @click="back">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>Nuevo lugar</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-card>
      <v-form v-model="isValid" ref="form" @submit="submit" class="ma-4">
        <v-row justify="center" v-show="error">
          <base-error :error="error"></base-error>
        </v-row>
        <v-row no-gutters dense class="mb-2 flex-column">
          <v-col>
            <v-text-field dense outlined label="Nombre" v-model="name"></v-text-field>
            <v-text-field dense outlined label="Descripción" v-model="description"></v-text-field>
            <v-select
              v-model="selectedPlaceType"
              item-text="name"
              outlined
              dense
              hide-details
              return-object
              :items="getPlacesPlaceTypes"
              @change="onPlaceTypeChange"
              label="Tipo Lugar"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              rounded
              outlined
              block
              color="primary"
              class="mt-5"
              :to="{name: 'places'}"
            >Cancelar</v-btn>
          </v-col>
          <v-col>
            <v-btn
              rounded
              :loading="isLoading"
              type="submit"
              block
              color="primary"
              class="mt-5"
              :disabled="!isValid"
            >Guardar</v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-row justify="center" v-if="showPolygonDisclaimer">
        <v-card width="80%" class="pa-9" tile>
          <v-row justify="center"> <v-icon class="mb-3" size="3rem">mdi-alert-circle</v-icon> </v-row>
          <p style="text-align: center;">
            Recuerda que el polígono debe crearse desde el lado izquierdo hacia el lado derecho.
          </p>
        </v-card>
      </v-row>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar">
      El lugar ha sido creado
      <v-btn color="success" text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { to } from '@/helpers'
import { mapGetters } from 'vuex'
import {
  PLACES_GET_PLACE_TYPES,
  PLACES_CREATE_PLACE
} from '@/store/actions.type'
import { PLACES_SET_SELECTED_PLACE_TYPE } from '@/store/mutations.type'
export default {
  name: 'SPlaceSidebarNew',
  props: {},
  data() {
    return {
      isLoading: false,
      isValid: false,
      selectedPlaceType: null,
      snackbar: false,
      error: null,
      name: '',
      description: '',
      transition: 'fade-transition'
    }
  },
  computed: {
    ...mapGetters([
      'getPlacesPlaceTypes',
      'getPlacesSelectedPlaceType',
      'getPlacesLocation'
    ]),
    showPolygonDisclaimer() {
      if (this.getPlacesSelectedPlaceType) {
        return this.getPlacesSelectedPlaceType.name === 'Polígono'
      } else {
        return false
      }
    }
  },
  mounted() {
    this.getPlaceTypes()
  },
  methods: {
    back() {
      this.$router.push({ name: 'places' })
    },
    async submit(evt) {
      evt.preventDefault()
      this.error = null
      const place = {
        name: this.name,
        description: this.description,
        placeType: {
          id: this.getPlacesSelectedPlaceType.id
        },
        location: this.getPlacesLocation
      }
      const [err] = await to(this.$store.dispatch(PLACES_CREATE_PLACE, place))
      if (err) {
        this.error = err
      } else {
        this.snackbar = true
        this.back()
      }
    },
    async getPlaceTypes() {
      const [err, data] = await to(this.$store.dispatch(PLACES_GET_PLACE_TYPES))
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
      // @TODO: Less hacky way
      this.selectedPlaceType = data[0]
      this.onPlaceTypeChange()
    },
    onPlaceTypeChange() {
      this.$store.commit(PLACES_SET_SELECTED_PLACE_TYPE, this.selectedPlaceType)
    }
  }
}
</script>
