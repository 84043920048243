<template>
  <div style="height:100%">
    <s-place-sidebar-new></s-place-sidebar-new>
    <s-place-map-new></s-place-map-new>
  </div>
</template>
<script>
import SPlaceMapNew from '@/components/app/places/s-place-map-new'
import SPlaceSidebarNew from '@/components/app/places/s-place-sidebar-new'
export default {
  name: 'PlaceNew',
  props: {},
  created() {},
  data() {
    return {
      isLoading: true,
      error: null
    }
  },
  computed: {},
  methods: {},
  components: { SPlaceSidebarNew, SPlaceMapNew }
}
</script>
